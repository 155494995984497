import revive_payload_client_3Nhb1FkU8g from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cj8LEz5dZJ from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xYvdSESq7g from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1rttDutGSL from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.6_jiti@2.4._pof3js7quv4o2t5mr4o22hoylm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bCWi0KPYEA from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_b2YNx0AaYJ from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LT7g0Y3Zt3 from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pguYzRwRFX from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8FxGFqCXEC from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/.nuxt/components.plugin.mjs";
import prefetch_client_2Xv7edu2fN from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_zccnqwliorlqsfa3m3zwjp2wnm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import fontawesome_48bg3vmeKE from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.2.0_magicast@0.3.5_rollup@4.30.1/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_eUEhfyDXEa from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_RQWb0QQhsV from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_QEL6iP7MW1 from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_36Ys6vYdtb from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.1_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_Pek5yi5Kcl from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typesc_givrvewwysda7xnx6nhgtjqw3y/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src786007114/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  revive_payload_client_3Nhb1FkU8g,
  unhead_cj8LEz5dZJ,
  router_xYvdSESq7g,
  _0_siteConfig_1rttDutGSL,
  payload_client_bCWi0KPYEA,
  navigation_repaint_client_b2YNx0AaYJ,
  check_outdated_build_client_LT7g0Y3Zt3,
  chunk_reload_client_pguYzRwRFX,
  plugin_vue3_8FxGFqCXEC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2Xv7edu2fN,
  plugin_OHU0vggoM9,
  fontawesome_48bg3vmeKE,
  nuxt_plugin_eUEhfyDXEa,
  switch_locale_path_ssr_RQWb0QQhsV,
  i18n_QEL6iP7MW1,
  plugin_36Ys6vYdtb,
  plugin_Pek5yi5Kcl,
  base64_2hii42QBJd
]